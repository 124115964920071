import React, { Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ClientLayout } from "./containers";
import HomePage from "./components/Landing/HomePage";
import { ThemeProvider } from "styled-components";
import theme from "@cashremit/cashremit-theme";
import Loader from "./containers/Loader/Loader";
import { ChakraProvider } from "@chakra-ui/react";
import NeedHelp from "./components/Landing/HelpAndResources";

const LoginPage = React.lazy(() => import("./components/Auth/Login"));
const SignUp = React.lazy(() => import("./components/Auth/SignUp"));
const ForgotPassword = React.lazy(() =>
  import("./components/Auth/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/Auth/ResetPassword")
);
const Unsubscribe = React.lazy(() => import("./components/Auth/Unsubscribe"));
const Verify = React.lazy(() => import("./components/Auth/Verify"));
const Company = React.lazy(() => import("./components/Landing/Company"));
const Careers = React.lazy(() => import("./components/Landing/Careers"));
const InvestorRelations = React.lazy(() =>
  import("./components/Landing/InvestorRelations")
);
const TermsAndConditions = React.lazy(() =>
  import("./components/Landing/TermsAndConditions")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/Landing/PrivacyPolicy")
);
const InviteAFriend = React.lazy(() =>
  import("./components/Landing/InviteAFriend")
);
const AfricanShops = React.lazy(() => import("./components/Landing/Shops"));
const OnlineBooking = React.lazy(() =>
  import("./components/Landing/OnlineBooking")
);
const HelpAndResources = React.lazy(() =>
  import("./components/Landing/HelpAndResources")
);
const HelpWithPayId = React.lazy(() =>
  import("./components/Landing/HelpWithPayId")
);
const Partnerships = React.lazy(() =>
  import("./components/Landing/Partnerships")
);
const NetworkProviders = React.lazy(() =>
  import("./components/Landing/NetworkProviders")
);
const InvestInProperties = React.lazy(() =>
  import("./components/Landing/InvestInProperties")
);
const DigitalCardPayment = React.lazy(() =>
  import("./components/Landing/DigitalCardPayment")
);
const WalletSystem = React.lazy(() =>
  import("./components/Landing/WalletSystem")
);
const InvestInCrypto = React.lazy(() =>
  import("./components/Landing/InvestInCrypto")
);
const InvestorsClub = React.lazy(() =>
  import("./components/Landing/InvestorsClub")
);
const InvestmentNews = React.lazy(() =>
  import("./components/Landing/InvestmentNews")
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("utoken") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `/login`,
            search: `?next=${encodeURIComponent(
              props.location.pathname + "/" + props.location.search
            )}`,
            state: { next: props.location },
          }}
        />
      )
    }
  />
);

const landingPages = [
  {
    name: "company",
    component: Company,
    path: "/company",
  },
  {
    name: "careers",
    component: Careers,
    path: "/careers",
  },
  {
    name: "investors-relations",
    component: InvestorRelations,
    path: "/investors-relations",
  },
  {
    name: "terms-and-conditions",
    component: TermsAndConditions,
    path: "/terms-and-conditions",
  },
  {
    name: "privacy-policy",
    component: PrivacyPolicy,
    path: "/privacy-policy",
  },
  {
    name: "invite-a-friend",
    component: InviteAFriend,
    path: "/invite-a-friend",
  },
  {
    name: "african-shop",
    component: AfricanShops,
    path: "/shops",
  },
  {
    name: "online-booking",
    component: OnlineBooking,
    path: "/online-booking",
  },
  {
    name: "help-and-resources",
    component: HelpAndResources,
    path: "/help-and-resources",
  },
  {
    name: "help-with-payid",
    component: HelpWithPayId,
    path: "/help-with-payid",
  },
  {
    name: "help-with-payid-bank",
    component: HelpWithPayId,
    path: "/help-with-payid/:bank",
  },
  {
    name: "partnerships",
    component: Partnerships,
    path: "/partnerships",
  },
  {
    name: "network-providers",
    component: NetworkProviders,
    path: "/network-providers",
  },
  {
    name: "invest-in-properties",
    component: InvestInProperties,
    path: "/invest-in-properties",
  },
  {
    name: "digital-card-payment",
    component: DigitalCardPayment,
    path: "/digital-card-payment",
  },
  {
    name: "wallet-system",
    component: WalletSystem,
    path: "/wallet-system",
  },
  {
    name: "invest-in-crypto",
    component: InvestInCrypto,
    path: "/invest-in-crypto",
  },
  {
    name: "investors-club",
    component: InvestorsClub,
    path: "/investors-club",
  },
  {
    name: "investment-news",
    component: InvestmentNews,
    path: "/investment-news",
  },
];

export const landingPagesRoutes = () =>
  landingPages.map(({ component: Component, ...rest }, idx) => (
    <Route
      {...rest}
      exact
      key={idx}
      render={(props) => <Component {...props} />}
    />
  ));

const App = () => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    let t = setTimeout(() => {
      setLoading(false);
    }, 2500);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ChakraProvider>
        {loading && <Loader />}
        {!loading && (
          <div className="client-app">
            <Router>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    name="Home"
                    render={(props) => <HomePage {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    name="Login"
                    render={(props) =>
                      !localStorage.getItem("utoken") ? (
                        <LoginPage {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/send-money",
                            search: `${`${props.location.search}`.replace(
                              /^\?/,
                              ""
                            )}`,
                            // state: { next: props.location }
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/signup"
                    name="Register"
                    render={(props) =>
                      !localStorage.getItem("utoken") ? (
                        <SignUp {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/send-money",
                            search: `${`${props.location.search}`.replace(
                              /^\?/,
                              ""
                            )}`,
                            // state: { next: props.location }
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/verify/email/:token/gbg/:gbg_token"
                    name="Verify Email"
                    render={(props) => {
                      localStorage.removeItem("utoken");
                      return <Verify {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/verify/email/:token"
                    name="Verify Email"
                    render={(props) => {
                      localStorage.removeItem("utoken");
                      return <Verify {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    name="Forgot Password"
                    render={(props) =>
                      !localStorage.getItem("utoken") ? (
                        <ForgotPassword {...props} />
                      ) : (
                        <Redirect to="/send-money" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    name="Reset Password"
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route
                    exact
                    path="/need-help"
                    name="Need Help"
                    render={(props) => <NeedHelp {...props} />}
                  />
                  <Route
                    exact
                    path="/unsubscribe/email/:token"
                    name="Unsubscribe"
                    render={(props) => <Unsubscribe {...props} />}
                  />
                  {[...landingPagesRoutes()]}
                  <PrivateRoute path="/" name="" component={ClientLayout} />
                </Switch>
              </Suspense>
            </Router>
          </div>
        )}
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
